







import Vue from 'vue';
import Component from 'vue-class-component';

import { Chart } from 'highcharts-vue';

// этот компонент работает только при динамическом импорте, иначе крашится в режиме ssr
@Component({
  name: 'AppAreaChart',
  props: {
    data: Array,
    titleText: String,
    showValue: {
      type: Boolean,
      default: false,
    },
    rangeDescription: String,
    yLabelsFormat: String,
    startFrom: Number,
    pointFormat: String,
  },
  components: {
    highcharts: Chart,
  },
})
export default class AppAreaChart extends Vue {
  // props
  data: any;
  titleText: string;
  showValue: boolean;
  rangeDescription: string;
  yLabelsFormat: string;
  startFrom: number;
  pointFormat: string;

  initOptions() {
    return {
      chart: {
        type: 'area',
      },
      accessibility: {
        description: '',
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      legend: {
        enabled: false,
        // accessibility: {
        //   enabled: false,
        // },
      },
      xAxis: {
        allowDecimals: false,
        accessibility: {
          // rangeDescription: 'Range: 1940 to 2017.',
          rangeDescription: this.rangeDescription,
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        labels: {
          // format: '{value}°',
          format: this.yLabelsFormat,
        },
      },
      tooltip: {
        pointFormat: this.pointFormat,
        // pointFormat: 'Число сотрудников: <b>{point.y:,.0f}</b><br/>' +
        //     'в {point.x} г.',
      },
      plotOptions: {
        area: {
          lineColor: '#336FEE',
          lineWidth: 3,
          fillColor: {
            // fill: linear-gradient(187deg, #C5D3F0 -330.8%, #FEFDFF 206.5%);
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [ -1, '#C5D3F0' ],
              [ 1, '#FEFDFF' ],
            ],
          },
          // fillColor: '#C5D3F0',
          pointStart: this.startFrom,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 5,
            fillColor: '#fff',
            lineColor: '#336FEE',
            lineWidth: 3,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      },
      series: [ {
        data: this.data,
      } ],
    };
  }
}
